import {
  ApplicationConfig,
  importProvidersFrom,
  APP_INITIALIZER,
} from '@angular/core';
import {
  provideRouter,
  Router,
  withComponentInputBinding,
  withInMemoryScrolling,
} from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';

import { routes } from './app.routes';
import { IMAGE_CONFIG } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { httpInterceptorInterceptor } from './interceptor/http-interceptor.interceptor';
import { loadingInterceptor } from './interceptor/loading.interceptor';
import {
  authInterceptor,
  AuthInterceptor,
  AuthModule,
  LogLevel,
  OpenIdConfigLoader,
  OpenIdConfiguration,
  provideAuth,
  StsConfigHttpLoader,
  StsConfigLoader,
} from 'angular-auth-oidc-client';
import { config } from './auth/auth.configu';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CustomTranslateLoader } from './translation-loader';
import { AppConfigService } from './services/app-config.sefvice';
import { map, Observable } from 'rxjs';

export function createTranslateLoader(http: HttpClient, router: Router) {
  // console.log('app', AppConfigService.prototype.authUrl);

  return new CustomTranslateLoader(http);
}
export function initializeAppConfig(
  appConfigService: AppConfigService
): () => Promise<void> {
  return () => appConfigService.loadConfig();
}

export function httpLoaderFactory(
  appConfigService: AppConfigService
): StsConfigHttpLoader {
  return appConfigService.httpLoaderFactory();
}




export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
      }),
      withComponentInputBinding()
    ),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient, Router],
        },
        defaultLanguage: 'en',
      })
    ),

    provideAnimations(),
    {
      provide: IMAGE_CONFIG,
      useValue: {
        disableImageSizeWarning: true,
        disableImageLazyLoadWarning: true,
      },
    },
    provideHttpClient(
      withInterceptors([loadingInterceptor, httpInterceptorInterceptor])
    ),
    importProvidersFrom(
      AuthModule.forRoot({
        loader: {
          provide: StsConfigLoader,
          useFactory: httpLoaderFactory,
          deps: [AppConfigService,HttpClient],
        },
      })
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppConfig,
      deps: [AppConfigService,HttpClient],
      multi: true, // Ensures the initializer runs before bootstrapping
    },
  ],
};

<main>
  <!-- Search Bar -->
  <div
    class="search-bar d-flex flex-column flex-md-row justify-content-between align-items-center"
  >
    <!-- Title -->
    <h2>Willkommen zurück, {{userData?.firstName}}</h2>

    <!-- Search -->
    <div class="search d-flex bg-light rounded-5">
      <img class="pr-3" src="../../../../assets/Svgs/search.svg" alt="" />
      <input
        class="search-input bg-transparent border-0 flex-grow-1 px-2"
        placeholder="Apps durchsuchen"
        type="text"
      />
    </div>
  </div>
  <!-- Search Bar -->
  <div class="album bg-body-tertiary">
    <div class="container-fluid">
      <div class="row justify-content-center g-2" id="layout">
        <div class="col-12 col-md-6 col-lg-5">
          <div class="d-flex justify-content-between gap-2 flex-column h-100">
            <app-projects [byEmeis]="true"></app-projects>
            <app-projects [byEmeis]="false"></app-projects>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="d-flex justify-content-between gap-2 flex-column h-100">
            <app-news></app-news>
            <div class="dummy-imag">Widget XYZ</div>
          </div>
        </div>
        <div class="col-12 d-md-none d-lg-inline-block col-lg-2">
          <div class="d-flex justify-content-between flex-column h-100">
            <app-weather class="h-100"></app-weather>
            <div class="d-flex d-lg-block justify-content-center mt-2 h-100" id="homeCalender">
              <p-calendar
                class="max-w-full w-100"
                [inline]="true"
                [showWeek]="false"
              >
                <ng-template pTemplate="header">
                  <div
                    class="fs-40 text-white px-2 pt-3"
                    style="font-weight: 500"
                  >
                    Kalender
                  </div></ng-template
                >
              </p-calendar>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-2 mt-2">
        <div class="col-12 col-md-6 col-lg-5">
          <app-setting></app-setting>
        </div>
        <div class="d-none col-md-6 d-md-inline-block d-lg-none">
          <div class="d-flex justify-content-between flex-column h-100">
            <app-weather class="h-100"></app-weather>
            <div class="d-flex d-lg-block justify-content-center mt-2 h-100">
              <p-calendar
                class="max-w-full w-100"
                [inline]="true"
                [showWeek]="false"
              >
                <ng-template pTemplate="header">
                  <div
                    class="fs-40 text-white px-2 pt-3"
                    style="font-weight: 500"
                  >
                    Kalender
                  </div></ng-template
                >
              </p-calendar>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row justify-content-center g-2">
        <div class="col-12 col-md-6 col-lg-5">
          <div class="d-flex justify-content-between flex-column h-100">
            <app-tools></app-tools>
            <app-media></app-media>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-5">
          <div class="d-flex justify-content-between flex-column h-100">
            <app-social-content></app-social-content>
            <app-setting></app-setting>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2">
          <div class="d-flex justify-content-between flex-column h-100">
            <app-memories></app-memories>
            <app-training></app-training>
            <div
              class="cardtip mt-2 row row-cols-1 row-cols-sm-1 row-cols-md-1 g-1"
            >
              <div class="col">
                <div class="card no-border radius-05">
                  <div class="card-body">
                    <h5 class="card-title" style="padding: 10px 0px 10px">
                      Special 2
                    </h5>
                    <p class="card-text">With supporting2</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</main>

<app-loading></app-loading>


<div class="">
  <app-nav-bar />
  <div class="custom_padding">
    <router-outlet></router-outlet>
  </div>
  <!-- <app-service-desk></app-service-desk>
  <app-footer></app-footer> -->
</div>

import {
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { jwtDecode } from 'jwt-decode';
import { catchError, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { AppConfigService } from '../services/app-config.sefvice';

export const httpInterceptorInterceptor: HttpInterceptorFn = (req, next) => {
  const authToken = 'YOUR_AUTH_TOKEN_HERE';
  const oidcSecurityService = inject(OidcSecurityService);
  const appConfigService = inject(AppConfigService);

  if(req.url.includes('assets') ){
    return next(req);
  }
  let authReq!: HttpRequest<any>;
  const token = oidcSecurityService.getIdToken().subscribe((token) => {
    // Clone the request and add the authorization header
    const apiUrl = appConfigService.apiUrl 
    const userApi = appConfigService.userApi 
    

    if (token && req.url.includes(apiUrl) || token && req.url.includes(userApi) ) {
      authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    else{
      authReq = req;
    }
  });
  return next(authReq).pipe(
    catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        // Handle HTTP errors
        if (err.status === 401) {
          // Specific handling for unauthorized errors
          console.error('Unauthorized request:', err);
          // You might trigger a re-authentication flow or redirect the user here
        } else {
          // Handle other HTTP error codes
          console.error('HTTP error:', err);
        }
      } else {
        // Handle non-HTTP errors
        console.error('An error occurred:', err);
      }

      // Re-throw the error to propagate it further
      return throwError(() => err);
    })
  );
};

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CalendarModule } from 'primeng/calendar';
import { MediaComponent } from '../media/media.component';
import { MemoriesComponent } from '../memories/memories.component';
import { NewsComponent } from '../news/news.component';
import { SettingComponent } from '../setting/setting.component';
import { SocialContentComponent } from '../social-content/social-content.component';
import { ToolsComponent } from '../tools/tools.component';
import { TrainingComponent } from '../training/training.component';
import { WeatherComponent } from '../weather/weather.component';
import { ProjectsComponent } from '../projects/projects.component';
import { NavBarComponent } from '../nav-bar/nav-bar.component';
import { AuthService } from '../../../auth/services/auth.service';
import { IUser, User } from '../../../_models/login.model';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CalendarModule,
    CommonModule,
    ProjectsComponent,
    ToolsComponent,
    MediaComponent,
    NewsComponent,
    SocialContentComponent,
    SettingComponent,
    WeatherComponent,
    MemoriesComponent,
    TrainingComponent,
    NavBarComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  userData!: User | null;
  constructor(private authService:AuthService){}
  ngOnInit(): void {
   this.authService.userData.subscribe((data) => {
     this.userData = data;
   });  }

  
}
